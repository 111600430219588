import "./App.css";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { providers, Wallet } from "ethers";

function App() {
  const [mintAmount, setMintAmount] = useState(1);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [connectedAddress, setconnectedAddress] = useState("");

  const [presalePriceInWei, setpresalePriceInWei] = useState(0);
  const [salePriceInWei, setsalePriceInWei] = useState(0);

  const [isPresalePaused, setisPresalePaused] = useState(false);
  const [isSalePaused, setisSalePaused] = useState(true);

  const [isUserWhitelisted, setisUserWhitelisted] = useState(false);

  let singlePrice = 0;
  const [totalmintingPrice, settotalmintingPrice] = useState(singlePrice);

  const [totalSupply, settotalSupply] = useState("0");
  let chain_id = 33139;

  const nftAddress = "0xfad2FB36F131F5b28E765458516755487f4799E0";
  const abi = [
    {
      inputs: [],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [],
      name: "ApprovalCallerNotOwnerNorApproved",
      type: "error",
    },
    {
      inputs: [],
      name: "ApprovalQueryForNonexistentToken",
      type: "error",
    },
    {
      inputs: [],
      name: "ApprovalToCurrentOwner",
      type: "error",
    },
    {
      inputs: [],
      name: "ApproveToCaller",
      type: "error",
    },
    {
      inputs: [],
      name: "BalanceQueryForZeroAddress",
      type: "error",
    },
    {
      inputs: [],
      name: "MintToZeroAddress",
      type: "error",
    },
    {
      inputs: [],
      name: "MintZeroQuantity",
      type: "error",
    },
    {
      inputs: [],
      name: "OwnerQueryForNonexistentToken",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferCallerNotOwnerNorApproved",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferFromIncorrectOwner",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferToNonERC721ReceiverImplementer",
      type: "error",
    },
    {
      inputs: [],
      name: "TransferToZeroAddress",
      type: "error",
    },
    {
      inputs: [],
      name: "URIQueryForNonexistentToken",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "baseURI",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "getApproved",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
      ],
      name: "isApprovedForAll",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maxSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_quantity",
          type: "uint256",
        },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "mintingCount",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "ownerOf",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          internalType: "bytes",
          name: "_data",
          type: "bytes",
        },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "salePaused",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "salePrice",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_reciever",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "_count",
          type: "uint256",
        },
      ],
      name: "sendManyNfts",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address[]",
          name: "_wallets",
          type: "address[]",
        },
      ],
      name: "sendNftstoMultipleWallets",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "string",
          name: "_uri",
          type: "string",
        },
      ],
      name: "setBaseUri",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_temp",
          type: "uint256",
        },
      ],
      name: "setMaxSupply",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bool",
          name: "temp",
          type: "bool",
        },
      ],
      name: "setSalePaused",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "temp",
          type: "uint256",
        },
      ],
      name: "setSalePrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "bytes4",
          name: "interfaceId",
          type: "bytes4",
        },
      ],
      name: "supportsInterface",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "tokenURI",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_to",
          type: "address",
        },
      ],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amountInWei",
          type: "uint256",
        },
      ],
      name: "withdrawAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  useEffect(() => {
    fetch_data();
  }, []);
  useEffect(() => {
    let temp = singlePrice * parseInt(mintAmount);
    temp = Math.round(temp * 100000) / 100000;
    console.log(temp);
    settotalmintingPrice(temp);
  }, [mintAmount]);

  useEffect(() => {
    setisUserWhitelisted(check_whitelist(connectedAddress));
  }, [connectedAddress, isWalletConnected]);
  const handleInputChange = (event) => {
    setMintAmount(event.target.value);
  };

  const SIGNING_DOMAIN_NAME = "WEB3CLUB";
  const SIGNING_DOMAIN_VERSION = "1";

  const whitelist_addresses = [
    "0x79eFCfa207AE82D76A6D81aBF5ec480e264e816A",
    "0x5ed61aF00697Bb0e14A9E05dCfBe2b23Ad86EC6C",
    "0xf70bEf21e12586c79747A4c3b4dB390Ccb92A793",
    "0xeaE3d36bD771A318772e43CE15e4680FA81f8875",
    "0x94F1075BeCf20Fd5B09B2Cf09E9459f29Acd99EC",
    "0x655907FAF378Eb7923Ca261f244396f0061BA651",
    "0x78Aa19D16f1677Ff9047aeC6332Ae414dDD45B32",
    "0x3C1C4d3323AC8B302854d11B6cCc06Cf86182d84",
    "0x50878dF070d764AeDe0D91101d2de83FdCDCf79A",
    "0xab4dF21b85f35cB670d42c3ADb52db5B85D88E5C",
    "0xBD5e10b570f94B4a8C732bFC0062E9A142726843",
    "0x8fD481206990641E149101F5Fc774bD3fd9613cF",
    "0x3a59842fBf20b54D84c3505fc206f5cF7b56f4d9",
    "0x93624532686CA92fd3dd1bA6825D764092b2544c",
    "0xC50B678B084b51e6f3598e8E3382fcF36c4Fc59a",
    "0x714e075198636956785B4cC08Be1C70d9361C445",
    "0x81063977d41514f8A768E9cfaDcd80c62Ac66A1F",
    "0xBBeFF5F245FCA19047C6Bc842A3940ff011f874B",
    "0x3DCc4Db2b9f6A0a932156B5702387a2Ec037cdAD",
    "0x65C1038997d2372a522e5c5cfD0095Fa97512D34",
    "0xCacF4F952020EFc23019818006708440Aeae30f2",
    "0xA5E231CEDF8F139BF4d43e7AF6a6C646d066d71b",
    "0xBD85d42f8f78DF7d332AC14d49b24bDec4A1Ba45",
    "0xf98bA301a1Ec86548f5Ef050DF0825eb1b114F90",
    "0x7Bd1BF0eDca9228E273F6f554337dE7c08a3EA1C",
    "0x705dAa6b2d462d286A930cDEA6C86B748F977CA9",
    "0x19032871531E3Eca68Bc3dEc9C3CC08DAE6cc1e2",
    "0x9fb69224d0E3Cb4594fB683620991098bB745c48",
    "0xDddcb1C27aeE1829d701f9C029171344e4c0C487",
    "0x893694Dc61A2B058549d24909Ba0c3FC53547637",
    "0xcd172923b656cceb7e48ab95ae05593010f17350",
    "0x3E944B35a9eC2ca86825fb29Fb720ab666117e27",
    "0xc34eA8EDC1159524Ac98A1BfE12cd4e31772E8b9",
    "0x708d0e3f0e5B833347a9D949bF91F670fa1A31E5",
    "0x4c2de1b38ca66C65013b402c338E90036a61A8Fc",
    "0x23a9eeA08fF12e80cc7FDc4e33C0343d9B19714c",
    "0xDD8Df9Ef236B1A572Dc895ecc7800150489Da9fa",
    "0x84F2c998cb6c5055B1DBDc3aCd52710cfd0FCC25",
    "0x828018a9Ebc5eeF467C56495E607F36d683F7241",
    "0x13D1F5e2afB18971BDCb1AdC08CBCD7379f87AAB",
    "0xca09ec56feeE2DE192E070D758BEa358B933488e",
    "0x858499D175F3345F2B178b50098ee9AacebFF96B",
    "0xCaCeEA73585Cb8c3DB6963A0c15C9B1A638A488A",
    "0x76815b9F470902E5785862eB9C37C3402fBFfBd1",
    "0xc58a700A3C5a8dC9f40a6aF91ed112C67dBD01B3",
    "0x259b67c475a793Fe581417A568eCa2CBA9Af88A8",
    "0x922ac53309afd56DdfE9B72f1E41079De2265560",
    "0x82c2FaF94b903239364F1Ed8C4417A303bc4BbDE",
    "0x70F9bB9fA579d25A6e38397E11473D257298905A",
    "0x5e5DCe828476A3b2344B84B9e49DA80879855166",
    "0xBf100008094A3dC5818BD80e2ca40dDB6DAdDf7A",
    "0x6A2C76A3115bd04249F9fd35B2C334f46116D25c",
    "0xb5Bb09EcaEcb7fCA6C837aB706Ce321B3A3dd949",
    "0xFaa9E491c978afA57AE882B14c1FFC43487693E2",
    "0xF08bFA5E448C1E9f0a3AE2f0114Ec38feE5f43Df",
    "0x6E0F3D6D3b3967EF5de9561D900621df8B47BDBa",
    "0x08a058F57670fF87D94Ac51ac62305Cc535F0F4A",
    "0xdaC4074C77971E6511b59a13E7a94Cd614Da87E6",
    "0x7963b06624dDD61caeB4a9f1cE812eBAd6ecFB71",
    "0x479714281560e46C99da9C9AC09A378E92b0CFf8",
    "0x381d263166F41c0c602790F1FecD8A3871bB7dCd",
    "0xb97cE7e921b9ecE83A945834C4C4ca273A5e4f4f",
    "0x331a4dDfbE680DdBdeA7223D9c79632A4E8AC5EC",
    "0xf0bD6d7Ac87aE03fe8d234888dbaE76e83A9c82D",
    "0xb7b168D05BcD5938A33a5702879769cCa1EBABbF",
    "0x7E178629c84E016Fb569050e22c30b1c597CB042",
    "0x46327d8209c61A641b6a118254505D747EE9C19E",
    "0x452C78CAaffDbA6e35F6C16c80B185d982dD47f5",
    "0x6405b55bbe6f333ebce7cf515266dc7455850e2f",
    "0x477dbec58bad6f6318ca70aff2d9953ba3b4dcbb",
    "0x6Be1188BbC6661988CF852D69D39E38e366D1240",
    "0x2B6CF45B5b3ABBE1BA49B7f5fCF649BF475333ef",
    "0xC20d8A0FC41880d4C1B81584D9ADAaEB2f61DaBA",
    "0x971495f068B16108E6D7F0064a2185a2b7f3021f",
    "0x294F86e4702678Ecaa0BBb0A368CD8D63bf09c09",
    "0x4162BBF1E6CE0aE276CED6f5Ff739a01aD7eAB14",
    "0x4D46C4d710167c7dF696E79B6A4574a2220AeFC6",
    "0xd2CB8abDCCb7ec6B0e605D372D94904051eF9F5B",
    "0x6D7699ab37386F76AE8A4EBD1F4165069d8310E7",
    "0xd3f5eE617D302Fb17A5Cf7fDB749fA638bC9ddcc",
    "0x95697876707424De3c2e6a0f1B6BD98D3c384F92",
    "0xdecFbe0f60a4F53FE8f697949fCf8883f6728234",
    "0x5FF038e9Fe73d5aDAFf3A5d786E748F11A5f2766",
    "0x49e9E617c40621Fb4eAaCb82459fCaED4731c200",
    "0x45d4aFBB8cA6A74a5b5f38eC6d44bbf55103Cb11",
    "0xcD3b8E6fBbA670c4772284077f85B5426aA88F2e",
    "0xd886643F4Fb00bDc617736591A993C5b6E6c378B",
    "0x75d42B919B7B678A2Bc63A93E4f2223F19908991",
    "0xBaA3f0f0983267D1B9847B6328eDa968Aa5cB0e5",
    "0x61C5C153B65bBC6482d8D837f6bE6a5D3AbF709F",
    "0xF1EA23CE57a9FCcE030EE92229c98a5299fC67F9",
    "0x6b85b562ED739C8C434DBF7C0b9602E3E37dd53B",
    "0x2AE6F87a75FD3bCB6333C0aD4Fad638E3a6F849c",
    "0x3AF093032b0fCA013A7d496AdDc24396cF892319",
    "0x08ec0f863414D21102F988baDb2C987eBF798b49",
    "0x4Cd531a5FC60Bebd96fD15d2C2b9B53d7A78B089",
    "0x492Cc9C6a51903342BabeA13797d328F188361d4",
    "0xB29127cFD68a4FaE24E07e511Bd91caC6cd2a2E7",
    "0x97327B8105DE613f2991BF4b52E807D2923F8895",
    "0xBb87AA386D750407e3e4095b0b420A63a4bc9F1f",
    "0xc7468B7D4086D15bf13D025A663E46E38d945446",
    "0xc4B044614d595102BB744529381F29E0c570968d",
    "0x9e8bB40f0340D73078e598e0A8F8f1e5e6A68d6a",
    "0x2E23B052F34BA9166D11fF4a3d6203cA64D1E20c",
    "0x3a2b8DDae3e02F0fc8D2e60648772985a758d33D",
    "0x7ee6f42490E573072dc93A7172A91925cEEFf1d0",
    "0x02Ad11f20a5210866914703490C79fbaC0F4213D",
    "0x9723cC792c32dcA2744690f99103d095eA149E82",
    "0x044E30D0c8310840dCF06367e5C6568026e7B4D6",
    "0x1bfECDc27810Dc3deB42Ce81e18E6CAf21E78870",
    "0x45f3fb932113CAeBA2502E6f1aE213a2E7f74Ee9",
    "0x8e2b2247a7AB4699C0efdfe31a96bF041e99aE58",
    "0x0592C8384E0c256A54A51712fF47AD44974993dF",
    "0x19eE74e32317b6F3396d9950DA88187a43f9Fa7E",
    "0x9839Df09cd1a45a1284D3BEDcb149518fb2ECF35",
    "0x9A700beEB157A5e60622DE5e2eD27899609A6b6c",
    "0x777C29cD351111F4bdB394Bf1530a7510fA2F46a",
    "0xFba71EbE40e6020cfecF5aF6D56386F68C09A8d4",
    "0xEf9C3BDA2b1B23Efc2eCDe4f5d04c9C914b42258",
    "0x7F547A03BA1D2aAA5d64bb0DcCD46912e8Ff3f5d",
    "0x91F4df529151bf84b2F8b7BdEb8aD139F543Ab6B",
    "0xBD530fB676796DD0A4404c40BB05ffd534071A2D",
    "0x23643862901025ffcb41b677624F365eF8Ecb364",
    "0x0409499F9bdE0d56F472AeBB24A4fAcEd4A2D19C",
    "0xf79f18C17429A6990448BDfc5fB744eF4EEc3cA2",
    "0x1BA3fe6311131A67d97f20162522490c3648F6e2",
    "0x5D06bbd476DEb800A1AE6a4a8Eb12628580D837d",
    "0x65b83a89C4DD5aB7bE50d75cD3e6e2A677115816",
    "0xCc23D9b859a24e1368e028AA5C64cB7cAf919E06",
    "0xA7759339c6786B2A02899CD9BDCfA4204E61AE91",
    "0x56CB938e4697243E59f3676b47fE48A1faA1f18a",
    "0x260Bb8EE860Ab86A77dD1A336BdB5b24B66A22fd",
    "0xFc2345d3Db99CA7DDD5497845d9773A0e7e3Eff2",
    "0xA019b8C19144Fd14b61436aB3eb39642434a1a47",
    "0x10F41efB258cf3675780dFB70ae35D0d84E94079",
    "0x5845Fc74B74986E7e65564326e0d2Cb94526239e",
    "0x1ABE0c41a1CaEAfccC00162b3Da23d820Acf2473",
    "0x716f6d2C08F55caDEd7f8F8CB0CeEA9FEb731f04",
    "0xc894f86C8461e6A2E94aB3a7e13A4757130898f2",
    "0x8211FA3F39E555748bfbeAea383A4EAD0c90C403",
    "0x6F20eDEb8E9C933CD53a51E460B9B1BaAf24d66d",
    "0x3aA4864a0410BaFa563092261fc9BaF5Da4C7265",
    "0xf71425556F36126Cd3c27Ba62F059C158438F404",
    "0x6278C2A7109c52fA6aC3Ec3e335B00b80b8410f0",
    "0xdb6C92a9CCAa27B394F4f496557C319dC22eBc67",
    "0x3a96D8b10a244385a5296AB7978416bebF71eDae",
    "0xABA4414Cc3bc819268dFdd14a8e5DA2300443aa1",
    "0x37667d93e047e1231e2CE3BC7a762dD36bCf6aFE",
    "0x2B78CA611c0a94E4812aF03Af9a024235660B513",
    "0x689Ee5488a551E5d67ce38D971d998ab6C0Fa6EE",
    "0x408f5D088C6bE5585b3BEcC016d7cb9574e10303",
    "0x79735e8DD7C153A5b918E756B3D1FE9A6ad4696f",
    "0x0aC28e7f11cD5106A8b2606DD916Ee652Fb49f83",
    "0x18f4FB5A5E95dACDe3Fa5b4723792a12A91B5A1D",
    "0xFA1e3b383Ce51310492056A038770cac39506124",
    "0x568fD352f4db677721529a383026BaaF54B1d820",
    "0x118e88A04E2EA02Ffe6F8Da4ecC8F03E39dD955c",
    "0x2A9961aA2B35A992026304A7B92Fba073a85eE91",
    "0xc6d890e6a295A828a3779d2BDBD6540Cf8A58b9d",
    "0xE18ff984BdDD7DbE2E1D83B6B4C5B8ab6BC7Daf6",
    "0x236ff44f77202E6d2E03b257Dd97CA70F9090Af9",
    "0xA414c131C83aaF0f4E8D0BCB3d907bC8E5c3808B",
    "0x3E7386cd6dA5a3c8B53d68982DB9f19D9346d214",
    "0x2bd69ec5C7129d384cdE55543E2A82074167a3fD",
    "0x1bb5f8dD7039575Ba95db047F5FfB1E53A73EFC2",
    "0xd9844a02D3F868F982e25d52547152Ca532F93a5",
    "0x053c9a5740130B75AA3C7dEd33fD6DB95C140e98",
    "0xfa8e073e9b66425e3db1ce0f83ce3847aae6f468",
    "0x6c8fbadCD63529feD4e153D3A9ee47e83D47910A",
    "0x634FC5f603417d1Bd1D9AB0F87BcA67f781D6488",
    "0x20a966f953C1e58A8d3b986649619B854d1DA120",
    "0x8B97e2ed2B5d83d5c04Df6F1Fe9c12B246A410CB",
    "0xF5Be0A7507Fdd9e40B41ED3E9376dCE96a9aD1Ba",
    "0xc73e0289b8a3e7ce073b598aeb211232f2355925",
    "0x2CC35b5DEC84D1fcb91a8F7Bf1e8d0Ac3F4d1062",
    "0xdC48a6e97d4ffB2145D79f8CAF76EB4833C1d51F",
    "0xA01db4332D4b646B75842b9A094f4187fb38958B",
    "0x081474eA6Ba4EE05b685cEac097771B121eD2Ea9",
    "0xcC4265AaB761Ef1bC2D9b7E6810925b4C6e79070",
    "0xa34f10Dad96B4d7B20176389b697B14840212895",
    "0xB6517C4cdaff29181Ebe17a8A52238d5859d7dB2",
    "0xE97d5efc1ed6932d5c4AbAf94a06c1143847bDa7",
    "0xCbB1B5d98d52e4724E89AccC629C1A66e118c2E6",
    "0x2309a6CCC10694F9811BB97e290Bb5B6675333Ce",
    "0x2b875FeaE1019f791458337e4E9d33Cb87B0B5df",
    "0x00629D7DB4338525a07FEd02Ad28AdE0ED7652ad",
    "0xEc936869035e343cd095Bf0d2402855dD2a35b41",
    "0x09686fd914EBB211620B21c0e783c2626c692e57",
    "0xF52B0CE61Cc5AED032BFA45209f86d37fEf62B12",
    "0x1E34E3f55B132E07e4b20fdEB8db8fEEcB30d669",
    "0x55Dd698C2a84AF14cef4c5ddde996F65C4C41a03",
    "0x2CFA2b3cC6be1509a1E9FAf4ecAC13469cf88562",
    "0x059705577622019d7c31F28fa5dC0771e12a99Ad",
    "0xcFFa66D1732Cb630fb188c4f202CefA2fEe5E471",
    "0xd56c1c5BCfB45bdDCe929A0131d2882608482931",
    "0x3DbF17953037775F25A765AcDf08670D9b2a89af",
    "0x5335cE47bB86e1b80d6Ee6167cEbA745bd3aF10e",
    "0xFdf4e146DC8EF8FB05e8Cc7EA6431aaE789D4901",
    "0x6e4ec434353FE3A7d9D0B5e0Ba4500d9945289Df",
    "0x7395ff9Dbaf4e42B5A7A1Cadb3e0Fe378fD1A25C",
    "0xd54938767f1B643D3026Fe575Bd05Ea8A6873ace",
    "0x0dE632daedd022ce3C39B4F1912Df5372485e9Fc",
    "0x3578609e4E78B41Ceb4Cee37403123Cabb697Fdb",
    "0x040B12297B8d0ab0D2016Fc823037e032E93DcBC",
    "0xBF6265A661a9024591227dd199FAc4347107Dd8B",
    "0x161424f311E92ce1D1Fe38B09218A10FAe8E94AF",
    "0xb531Ccf78ad70A7709E42B67Db402Cc4E3f8442F",
    "0xa067bBd3aC7D549b7fD537588655F291fdA9e219",
    "0x0AeC6d77405E284A0e1eEA22B5d1e8fc7E6e3677",
    "0xba55A4AD8B09C532fD5f330768A5C415e5cd689B",
    "0x7F4F2c393d82cCEEBD44664Bd1F40BBa9ea66C47",
    "0xF534528E25717689fD5885dFDaB53d9735A20baA",
    "0xdDB76Fb971997A74c7fB6C1026Ae7dbFfB1e82C5",
    "0x55a3536a62d800EdeE36940E9303675AAf2d589C",
    "0x06D43fE5B16a6783Dfdaff1c6a4024e926E89E1F",
    "0x6F51cFD9E965629944f4C9f23DB8F9737D01F23a",
    "0x7cd89D6B84A656785F49C18eC0C89a75833465e3",
    "0xB914970808560785F34BD52277029e6859c7c478",
    "0xf2A199E05fF54795376265dFFA4c42a37a8E6858",
    "0x0aAf12E967BF6a00C863735aAb30031A9aaaC429",
    "0x68E32b841a1dA3845493b320DB611a31F2519929",
    "0x397712e87cc37A8472dE68953F37d7D9C2A97099",
    "0x30BC82C05DA98599C705d89e04BF718F716EB52c",
    "0x5D30dc36255aE4aD6022c7b8bA640e709993c4D4",
    "0xAadc624880374A539556D2c644089F60501623C2",
    "0x69450f0DfD3F3D4F04191c60FA64bc9c82Af2dA6",
    "0x76f60DF945e09c8bA029610E32D07a2cdF0690AC",
    "0xe5A9c7264C283A6EDE3631256414816c9008015a",
    "0x36B1a439E1bbeF08f638B6322aC635F7133d8978",
    "0x3c92E9C75b6aFc19eEA3D22f5EB210C99326CC2e",
    "0x0F4485C59b093c51f510372e5b025Cd4F5850CD0",
    "0x1Be4001a39905F533e6feba0400D339F7009ff62",
    "0xDCE95A016f359383A617A9c20913ab803bf6bB3c",
    "0x887293Dc59B6235c7a7b8d11439bcB77E97e6F89",
    "0x13F7F55D5Cfd5Aafa78B85C6c43dd670Faefc7a7",
    "0x1c87fe34A4D6346E0e10121ec6BA61a9a92d5A6b",
    "0xC1753A280ADc4EC35Aa9FA299D8f3eB722b4cF47",
    "0x58022674a3BCCA04Bd572dE107D0109d9D65DB12",
    "0xad594bf9f16BE829606200A6F507e13E887Be740",
    "0x0754E0B5898F76C9Bc03a3646d3C9dEf19a2f04f",
    "0xf4e6dF16A4C23C1dAc263279200Fe6eb3471c604",
    "0xB0300E0b9DD3EA9CFb44a1e12C9CbB7E0F4204C3",
    "0x872e473B0cFE45FB450888D18A28e7a61e1b142A",
    "0x1a6DE23468517C08672B6c85B4cf0e4BAeB76e6E",
    "0x1ADBFfd3c930a6EC25119850dF03Ebc572028697",
    "0xd545568940aa5918B9d47D2C4Be733009b8A20dA",
    "0xA2CD88166D341C845Fc77F4fE54E743B9E5F5606",
    "0x6b5126A034e6D28F9f742Aa109aF546f5EaDC352",
    "0x99f9E82934e43f5dBf88d15b94B9eB57F4Bab35a",
    "0xbAE0D86b10a02F2559C3322e16F105D8c2EDf550",
    "0x097d007e87748D60eD233de71354EFB78807ec1c",
    "0x7615eB1788AC1faC205CbF3fE2a56c416B47B4B7",
    "0xa0845bee6BE7A0abA482b7ED8264D15d24E4Ebf2",
    "0x851E4bD07d95Cb9d2335382C7B46eDC7014b5284",
    "0x2b5fE488a72D13e19E8422A2cfFAAFeec92bfa4A",
    "0xc5ada99E85d426CA9BD70F47B52DA0BdCD1EADCE",
    "0xF14C82F03a209545Dbf5a80165c81D8159E778A7",
    "0x599a7C701c6dEEc113703eE1e444B8B66C7eB683",
    "0x0e9EcaBB2BB3bEEA447c9f472305385d9973B24c",
    "0xb21343E8DED1Ecf15900Ac4b40eCe8FF11f81417",
    "0xE97C1c2522fe0FF1FEbE06128EA820E5d13e21f0",
    "0xbB4C685807eDE39EC5348d6aC895B56FE20bFd81",
    "0xD20812410ab5eEE7002Dfeb7AE7e2CcaCfC7292f",
    "0x2728d49f65CEe0eDfb7375ee5d4Db923cbE0FE2d",
    "0xB387250a3E02A6BB11C0B26641E7947990de89A0",
    "0x8952a5FA849C6d2d1FE03F343144bC917d5463e1",
    "0xEFeF3890C2E7d8d2b5731C5A4197de55F68Aaf64",
    "0x75c806A271A1fF8548cDC3d3a7EfFEc16eBd1040",
    "0x40E5222421c05BF0E08B5c596C351Df39967B1E5",
    "0x815C2C7a972061bcAa9d1ea217a5a206B850846c",
    "0x8315Ef2851BD9F4662C1303Aeef02141b2D0f9A1",
    "0xCb1292e871a488904b7F4840294471A4e5527d80",
    "0xFF8fA273530Fbb562c0162b536F8b02d269f6D8a",
    "0x54599738579E6660805B9BAb755b4b91A6152d97",
    "0xb1994a6F65Ad4Ac23dADF7825Eb6dCf013fa780D",
    "0xc11B7962c06757A6906F15CfF8B4A3BA1e717f76",
    "0x673215B4020dcA871a94a2eC0ad5Bd9d7D9704a4",
    "0x937DeF7c0Cb56D40c2A255FDAFbE053fFbA7E894",
    "0x9B52f408a3FdBCaCa3a61bB1354d287123B03Ce9",
    "0xc2A1ba2182F0a7D9710B51589DCe39A0c29cD6A8",
    "0x765ef32de1d9A7548D1572881594Ff19f3Eb8548",
    "0x5bE2AFEA39Dd7Fd6FFdedc48545643F4bD0ca5de",
    "0xA73Ad570D26d490569a7D069aAE66C94B7AccA16",
    "0xfC5B1a29786785dDEb7fcFAa7f386Bee99736A3C",
    "0x2610623C0DfE1338B172c7D6a8C0F207FEB5b2bc",
    "0xeA5876991ca48E366f46b5BdE5E6aDCfFA2000bc",
    "0xd8Cf66405cD3A106AC9316dd99cB90a684e1901d",
    "0xcBA76f016808DdEc083EB8BC0De93eFcE96572eA",
    "0x0e2D0167495792D50001BF88c3B5587A16b0453f",
    "0x5c4aA73Cd04672cABEf2749F30f72ac7d429B72c",
    "0x548F21580cc383756BEb5a212C82ba1030E7fAB8",
    "0xc6DBF031790710E6D249121dc371D8575805515E",
    "0x55791ad93a436661F4A30F500b6cd29FCe86968d",
    "0x862DC0175C1Ea8c0E39199503A3354eF1d54733D",
    "0x096714606e24d4f44E7cFDD5CA6a35E3Fcfb8375",
    "0x61D7f4Dd8b5D8E9416fE8Fd81224171cAA32112b",
    "0x9455B2Be14c63aafae8DeAe5B35d1e723B521721",
    "0xD7Fb64b6ca94382f3D8214c4412A8F8dC9Efd1ad",
    "0x9d24ea362bDd284c7E7B5ea9ee7013e2FA60F57e",
    "0x597FDEf2a75C3887F38cD6ccDcf1C9e68bF8FcA8",
    "0x3232486CE6498c41B2df47A1F247f9F4DB8029C3",
    "0x8AC65B1D807EB2C8BbB04B90c3Aee2E49aaCD6A7",
    "0x217d846F117287F8b323cCf83d4Eb122aB7c56fe",
    "0xF9471a1AF8836373208EBB96CdB2F14091b61c57",
    "0x23532E2795E584c10DBf30f36E0bD38A5aA8b9C2",
    "0x585BA46F1fF2c3b34fB4c6FD74544e0d76930ffc",
    "0xe03b60DcF672e72Ffc2403F11236dffD274F45eE",
    "0x7F2a1ada49bB63Ff1B8F8f28a310c2D59c53Ee41",
    "0x159F921C55539831AacEFAAe2a587C28a2B9C769",
    "0xa92FCd2e64f3092B3D47f0fdB1AD209dC06f04b0",
    "0x726D62f63474C260ec9DCAcf69246282C9d2a5c9",
    "0xb4c744D23Dbd9E356f2a25cFe59e671E0E1eb9aF",
    "0x0E2AB8B26837976B111168F5982A4Aa238FD847c",
    "0xce82Ff98c7C84330BfC36263276aB802C9fd702B",
    "0xeAC88E10DD8267BC5b0F02804C6243d3EbA64E0A",
    "0x782DFe0f4f7cd1903f1A63838502d51255551474",
    "0x7CE701EfD548BeCeF46fDc61091590D6d6e662Bb",
    "0xE22D911479C9D288A21Cf171A4F7904CC4868151",
    "0xc47F603b9ccf70ed502412720672a6dBb24D0885",
    "0x17c8c6c1D1f6E279A05805b17eff80DE422B65e7",
    "0x7372Aa1f3455a2384E210837ad18dA993144d40d",
    "0x54a8fAe6DF04050497bc0b7F8A87E9c6F90b3070",
    "0xE156A3D73eF8CDb8085D8c1C413704dc4aA2C4AA",
    "0x5AF8f36884FE34f9413a1a7B7Ded764eEC121888",
    "0x1aF4c2950C5c892D1F4aacd60E8f656A14555E5A",
    "0xCEaE09f6c17aC7155Acf63680A641d0Ae94A3e8C",
    "0xCB9B57a1b038ba8ADeDe667c6226748a866E9268",
    "0x5C79A96906acea87502b8BCb28FA30bA0ed29288",
    "0x775CA09237bC5bbEc7C1f9EBeF6d9E4043935912",
    "0x0e66A715078a09678b040Aa654631EefdCC7d234",
    "0xdd4B0d57Eb037F27d1C52d23439076EE78E904B6",
    "0xd5B566835aeE8d7eDDee7771E74fA026B49d0680",
    "0x1eC8B985B23C62B00A8490840174AB7C10C636Aa",
    "0x193f0c5AEF38f6C05c0FAE8ECa654864d730A85a",
    "0x4d1CB1C6Cd01b93735619fC1340E413659Da1C44",
    "0x95fE08910296Fb44db4Ce7639DF62D141cF4BBcC",
    "0xd332816587800C219356C0A0765e164e93997a51",
    "0x9288a0c657c43182AF1E6bA714410a627214c4Fd",
    "0x518569dA0c7283946BD334e4bbdF90e45d9e4309",
    "0xEe2916B3D0b9242ffB3d3e9b3dEb06256611b9DC",
    "0x6fD44b1da59a637017b253503bcB9a67b0896107",
    "0xC6a6b140293BC061CDe95B559e01a655c19BEAAF",
    "0xa674a77edc7ce5586d494c79b6fdfc4f76b96695",
    "0x4D6398268967115FAD6a8613a97bBB438b631283",
    "0xb54Ca7687eF3E6FdE424c797C968b47b9fC408f4",
    "0x0165dfa0c24594ee8e4b75b1de5d411d6feae33d",
    "0xe11d08e4ea85dc79d63020d99f02f659b17f36db",
    "0x240A618581FebFD07Ad6b20ed4e5E6e8FEF3561F",
    "0x077C51bC329E3212Ec95ad4cE2cB0Dd440D947AD",
    "0xc1688748160128A3FEebCc380699DD7C26cdc418",
    "0xaf23Ed1361ce0B9B497Aa3D38B69b4eCf6D9D48d",
    "0xE35204e44A5CC128F2d3AdbC75c0e17B7F6a93D3",
    "0x525a278DbFa4D9DA14700b7fD435Af21b18B713b",
    "0x363dEBa39C26918594Aa8C0319293FA50BE031AF",
    "0x3694df92ce74c1ce744B377e73cAC8167B1D5474",
    "0x41227e7cEAF909F7f71142d8c2A9196F6EFc4efC",
    "0xaf3a585C63a607cb7D48753eecAC3ed00e2E05E4",
    "0x29CdbAe3D7976CcbDEAA22264Ce9af60a265d0b7",
    "0xc14D88F4A83cfCA862cb012a50d51002DF7Bb80F",
    "0x68356a6B31baa6B5562966F5EE6F4C576A97FeAd",
    "0x3b042ff4733430F74A2CA2d7BAA2FE4B34a7a278",
    "0x15719D37d81A0A490AF6B143FFB3c84613d77a7b",
    "0x6E32a901f4A1Ce4ED86e1a7656A36dC262fa8eb6",
    "0xA6171B1920A61B8b76b26839A61F6678DcaA786f",
    "0x9eadb4c0e373404e89b8916aa295b9fd597a0d91",
    "0x24Bb3FbbA92B9697487b9Ce7e216C03a7e6a38CE",
    "0xFfa6966DD9b078d09e5396671b73098d6d91fB60",
    "0xCFa91e557B07a81D0244cF902E27934B1A9BDE74",
    "0x370aAF21AC3a66Ab87eE886f1a3FB1397eF60628",
    "0x49F9311f046086576DE2c3e8861E864bF7eF6B7a",
    "0x9DF797b49989fc6c3F82F154d2bE6Ffe2b496Be4",
    "0x20903153d13ec240fb89faebc9ef65cbc6d80eb6",
    "0x6984abf56f07a9b77f9c86505662df02abd18ca9",
    "0x788ba8173f2fb22AB45d2537c9d9f81fBaAdCa04",
    "0x533c07cd7f2eb451947a8dd3d4e97cb3c88b57f7",
    "0x07241570B4E2c28923d23F99a9Fa10B75841056f",
    "0xc386F0b26f71a80BE8E0B06bB6F2321941d8C469",
    "0x3B3248dF428B22f131cbbc075Db3997541330094",
    "0xCe1aB75007263c5e7F86a800E24aa752EBdC1957",
    "0x902f36D185D241a030d03d8ea1BBc75aD601C750",
    "0x2ea35bb7D5eF8Ca35FB9AEa079FA4598b757ecC4",
    "0x651a829851CeFDd6ee8dcba68A4eDB9d6186515C",
    "0x0cEe40f8D595e91c3b79CbaEe7403Ec361777462",
    "0x2FFd1262034819Ae47eDB2170fcE421E34f9bF71",
    "0xCB140e8cb3776A4Bec10703c487b9719D6c07BAc",
    "0x6fE18B15E7ac6D43B30a7EAB2F75C31D51Dc41aA",
    "0x6EA8d107129FA024cD0E87F46665fe2af81d0486",
    "0x7B239B4A8006267Eb2E8b5e6154dec943b1471fF",
    "0x17734e55fEB5D91323e3b75035E50DC8379F16EE",
    "0x8e5C702f15d7Fa4b129aF7d538804Bd7a0DA2D57",
    "0x97535c0A7089A170a086118a57b6FE289Ca76318",
    "0x08220413dE7EA1899A692c0AF3930F14Be60152D",
    "0x6a5e857D6Cf35083480224AE0619BB801acDC4BC",
    "0x6DA8df904F8C8253F353Df42985088d8A4c29Ce5",
    "0xA3E5dE933f49994150bfFA9329FaCf75C70c2908",
    "0xFFdA0EE71544467687722f5072b71d692b2668AC",
    "0x5D9bf0bd3a7c925c839588eC3BF118C93CD8f57B",
    "0xf4f80b44e16ead75f8e8a96209c65464361e8ff2",
    "0x2fa0439e0f465867d17cee5fd634dd4cb0d9d090",
    "0xded2d85c00397652323aeba140cc2c920c7fe763",
    "0x42e094bd9f2d03fbd8fe16c55dbd17197c5642e4",
    "0xa958c2b0f4abe7a8def460b84f58bd1c2fe9bfa0",
    "0x0eeeb69f06bd659c13c62df46199bd2d5123f84b",
    "0x855caa3521eae3a816281790a3b6da1acb325f2c",
    "0xae671a7aafbe75fb9e5e2364d8fb7d378073996b",
    "0xb1422a9945256cfa1dfaf9e8f05966ee4ce78a90",
    "0x3c8b7da54d753fbf8dea7617e3bb4b629bd10186",
    "0xc18dc7d12fa7c05bef81f1af6ef773159779406c",
    "0xf7410328c0ce1a61e436d3b62a9adfdc6d205a4c",
    "0x68376130d60cb27e0fb6e94a01ca77e4a8550872",
    "0x933c3705fa685fe8b84a9f628e103fc26c581b6f",
    "0xcab6193901887968cd656bbb15ffde19b0eb1c37",
    "0xcafa2a44c5fe87deb52779480b689b858f49c29b",
    "0x05da4f177de5ede2521ad79948e5ec4c55443de1",
    "0x0ad9e1dd0afdff7379f5d9af6f7d8e55dfcf7572",
    "0x2b35569cef440649b11355dd658787b61a3ce3e4",
    "0x32e18da58ebb46f8f8ee11842dd5c759895ab13f",
    "0x4c23464bc7d851b8eace8e5d6e0ab85e42105f32",
    "0x5fadda34ff120178372d6489a52360f35b52b4ab",
    "0x95ccaf23a6061be8e56fdb1ebf73d0fd9e675b84",
    "0xbdfa755f6ef4b8e4d5083466f3de2512f6ad0a54",
    "0xc6ddd3e9e2debb5247877fc16160963682b6d1b3",
    "0xcdbaec32ee867e7bd329fbba630348c37235d453",
    "0xe8fff56ebc79a8a66d2295750e7ec9991410c502",
    "0xf87d79300e0d2f4f7da47aa03f535bf16a38179d",
    "0x0292ddeb0b834cc0b1329e1943049a0c1e21ca0f",
    "0x2f6af8f85dd618e6ccd1d193dc11a0e608858de6",
    "0x366fd343f554d9bb63d7d1ebcaff8ec6c657d9d6",
    "0x4a6d41513783b0023c4de563de751e698e5f962a",
    "0x4a80cc60d57b7680abd0be0c8c716a532d629c06",
    "0x4ca107925c3aec6ec009e7fe8f0860f0db13e73c",
    "0x4e71fc7c6174fcab3d7b79d1f07ec6263cc3ef7c",
    "0x5e35a74a92d632ffac2d7db48540b015aa3faad1",
    "0x687ca7c90bbb9aa06d43b2941f4163fb7902671f",
    "0x744996188f75d11b2d9281f5bf9129370e96ad92",
    "0x75aac55b3d74de63f5614cd01d3b50242be9ef42",
    "0x81ab1b7d19b6688741cf213fffe494d252d56008",
    "0xa793ebc07b3c44123933c3e4e2b0a7807fee8257",
    "0xcf2cd1f063405a0b10561a615d4a349d08417a0f",
    "0xd2e7454dfa71719cddd44f7b88873963816ee47a",
    "0xd5d30906f6cf5bc0682ef355d970b10b43c752ab",
    "0xedd27802b2d30a93fde059af839abd6bba29c169",
    "0x073a1e05e32eda11437eadc5e0f908a2debe0a60",
    "0x1116b12cd7056f0729875d1197a45802b4f13be6",
    "0x118069824142e52484a965de6a617f7efad42890",
    "0x18771d2b99fc5fa2735085e847d42c3c0cae3a2c",
    "0x1d2985d34c3dd0f5470a1efab95eccee2f2ffb1a",
    "0x1e2a4dacf32e4ad8a99c0c2146e835a186cd0475",
    "0x25862e7070f52140da383446da0cb34f7ee08c6f",
    "0x291e0eab1bcf9c38ac7c44dfc5edb3a9b13f7356",
    "0x2f8e094947f2ce71a63ff06687e8c7aa2b2cd850",
    "0x3590ac4e353cb3a5d5e5b909511e5c06627a7b02",
    "0x399544edca1a4bf24dc0aac29fa0e56d7ace8181",
    "0x3dbe462a0b305a2e0949b3d0cfb6ee4ad922daca",
    "0x3eb41d1154d331a0e2e3ee160e84086e1f31e6fd",
    "0x406140b828cbad78dcab5d8b0b8a8e13a7223f2f",
    "0x480040be68dce4a47910e80fe905153462cc79d7",
    "0x53ccf8141fa50a57ac1a7a7dba87ad513324c24d",
    "0x53d950a41237f1177c452ee243696fc0338ed944",
    "0x5d59371a7907442b0b1f2b2fafa9227db4f6174e",
    "0x68b4b7e4f7ae4be404360849395a7a48f2f3ffba",
    "0x6f109abb582916997d533f557bd7cc311e6841c5",
    "0x6f23328a31d7fd6cf87713f357b9571e551f5e67",
    "0x73a3c649d33950269db55fb208e43c7956ad1109",
    "0x79555b9c84024d8828f61774db92c392f9531c0a",
    "0x7a0620d17cb73131425cc89a080f1374a8943846",
    "0x7a631b4a6b1d0a6b1e433d92690d7c8aba4f23b4",
    "0x7c665f07f04e9e9645876312e67a67c5a091f82b",
    "0x7d2cb6e5f16b40218a1e7430cebbe22ef9692dd8",
    "0x7f8a3979b714dffd883c470b3c1717be48e1263a",
    "0x82da6f1125ccd6f6e60a0b95bcc4086ff55cd3fe",
    "0x91b1ad7bbca04706cceb38daa04f183ed3ee1457",
    "0x933708db35d0a569a8270f04216b2f7051b2763d",
    "0x98a216a83fecb7e0db399c2a6bc15df3cbc3826e",
    "0x9e48f12ab6908b3ee48fb137b72114b998e44523",
    "0xa0369e79c76b34835eb9b0684d33b8d31f78b463",
    "0xa373c439f8c47276fba8786567d1044e2c6c7c3e",
    "0xb57d8618aab5da16ef48b557ff150fda53b7cf0d",
    "0xb621a8a894bbeb16158c4767601b4c53d2517585",
    "0xbc11924ff88d3f1027c5ac2dc98b89a68a6a171a",
    "0xc1a2fc82e5a1fda18671708f039114a3542b6354",
    "0xc1ac911f9e9f2c3262ffce96b9998e4a0c243ef4",
    "0xc6d90edf79db0f0ff3a5fc342e4be49531df5f16",
    "0xc97e8b0baba04f0ddf1d9a7631031e1a240f34d9",
    "0xcf3fb08fb4f6c27be232c1c36b06bbeddd2dae01",
    "0xcfbd6266610af1fc1c0f75477358a4cf5bc1ad84",
    "0xd4076014fb9dcb8f307f05eba7fbc951b7ed3399",
    "0xd448100e6d350963d39955bfccffd34b6fec47e7",
    "0xdcc90475f6b41acf6ca1d192b9bdd038df0e72e0",
    "0xddbbad5cf3ca541e469372ede229fe3ac8e41ceb",
    "0xe0f669b5e05fb19f0fe4ef5e765401ceca2e4252",
    "0xe6787816c3f807c855d480474074b4409d3f2d32",
    "0xea284e9856e4c898b4ee323711109fb0af46218d",
    "0xeace06c09a26aa0b44fdd6bcc730db08e4711210",
    "0xedfaea2d08fcd8e814c480d3a55b49f9550c9df6",
    "0xeec21715c69fe1140c16e756f91bca467327f0c3",
    "0xf8cf5caab8a3c16298eccd4450844c601f6dc0f9",
    "0xf9115276c13e6ec230cef20acade3d8bf87870d1",
    "0xfcf0d478b2f9cf51e34db1048c0cafab6a09ae1e",
    "0xff5a22d980abf1e41ad45d8908fb6daf4c8e3014",
    "0x7ca43c9ee1fd3e5b650e593ee59759f7f9dfa3df",
    "0xbd59faace77ce874d0a2de6b3dfa3344add03fae",
    "0xe94a3917cc7837012c4413c80a79fd730182b2f7",
    "0xf67b18882a89602dcb21cb0923e4e38ce5f8f423",
    "0xdcf4ac0d7a4e97f09f4c5e3d8e9396d671a6b646",
    "0xe3908d57d9e26f27ed6663106260ada2b97cede4",
    "0x161e65062b09acd163f4a024f0538d57033a191b",
    "0x55ee8734c3919a1fc0b85667e7de186ad43a85fd",
    "0x7e3a8de1ccfaa295189583b5c2b89cd99304fdf6",
    "0x87faab05be6ffd3143e2150b2da656fcc0ec40dd",
    "0x8a146600b7b75aee80163786a449a9f744ef73ed",
    "0x9546e0cfc3472e96f4c964ca1789f1fa52f1e26a",
    "0xcefec054bc35fe8ca44ca24b78c7738cd85f5db1",
    "0x0386b821e5466fbfa0fc3befbd055332eb7c8e0d",
    "0x1d1082b427dbcf9c902ac8817e46131f4cd08bc4",
    "0x9747e060f58c25daf8c05c3414a2a4dae99c721c",
    "0x1b759a0afb46e377fecc0f5a186d235a09f1e8ec",
    "0x2c20da820eebe9da28e3722da1d00e60e4eef063",
    "0x2d9cbc4ecfbd1b8f66aa798fd51585ae058daa8b",
    "0x3c8837de7e561eecd9ac19b0acdb62c6c6f43cc5",
    "0x3f9ca1292a7228340a925c34cdc8347e8113cfa5",
    "0x464725da1ebc575a8192da6cacb01b70df0be0c0",
    "0x4839a9410e351a9312d95a01c1b47dd02a627e9e",
    "0x559843947dd3b157bcf2c890742f3dbc61fac2ee",
    "0x5fc1e2321d0d85d603b664ce2848fd2b37f94ace",
    "0x6b75d9504184f6eb3feff0cb3ff9b2b6af756a3f",
    "0x7373043ccc6186caddb2c9e69e60ffd7ffcd7621",
    "0x8eaa1da3225ef5a18e0597165c320448b4f80587",
    "0xa0a55a93043def979a738ee8984d2c9a12c77d6e",
    "0xae29a11f6c3f9f74ed60b2a3d8364c8d1e0df68c",
    "0xcb44375c6170e39224ef6e91108f06019762948f",
    "0xcd9df95c29249153559b6fa1dac116f648ef0b4a",
    "0xe4a22b1f8a09473c79fbffe8ad5cf0cbbed64966",
    "0x00ea10c0373fd9873a4a259994dd2d215f8024d7",
    "0x01b3c7202c680271d7e4091f06b31366d68680c7",
    "0x05a53d40be5b8a8bb07abe80427b648c200b4a5c",
    "0x0c4952550465840736f161d2d6878b8c3b31f330",
    "0x0cc18b17d8969cce87cbb5ebc418270bbb70d58a",
    "0x0f5f46435e84f8792cbdedb341780eeb716d0813",
    "0x141e3822c900b0582eafa4cbf94da13706a0be78",
    "0x19bbbda73d5b7bf30bd23545e0c265a19b339d53",
    "0x1d81a65936491a54323e862026ebc11e24e725d2",
    "0x1ea05e5b973e9c9fcb4b0f39dd33ae498d83a915",
    "0x20099eb8dedb9617fa058f8a3479ce09f417b00c",
    "0x202968494f6feae9a4770dc8ad29307283ec8f0a",
    "0x2254cc47df539ba6167c578e60f780ea79753f2b",
    "0x282d27c1cfbe951f141a76ee4e907f911edac03b",
    "0x2a11dff02e83c170b30bb9acb169d40921616908",
    "0x2d632a82bc1784e0d8b610febbf2c2875f676221",
    "0x2dddff8c33c2cec0d20652b7847ea2bb919a4e56",
    "0x2ea2ee6ea54618d66d2533907d99fa8509bae555",
    "0x31591cc0f52d2caaa5f559919aca6efd87d6f8eb",
    "0x31ee75ab18b092757f8a9436dcf66ed0a7334778",
    "0x322ecaa66f9713eabae998b5855fac29e2668cc2",
    "0x36719d16c8b02243a5b6650ea1c889311d0bc34b",
    "0x3a272a423d3441b28a3cb3a06c20d123e600bc0d",
    "0x3ac4161dc070da4c6734c75538bb6ac64a0f0e63",
    "0x3b1100874d4c4624f5287dd5bff4a452a241bb3c",
    "0x3fb60d25655912e9c8090d8bd2f5c9c47d3d3283",
    "0x42fa432c81058ee8a3c05af9dea94e1b4e3a5bf4",
    "0x4e2cb8077fc42901405e13d658e81129d7a01954",
    "0x51a8b04e69b1e8aec0a110a9e9ff9870377b1993",
    "0x54f000dd484270405ae6d4ea5e0977d2e45b7051",
    "0x57eae5cfd3df32e00bc09f5ace400b28e25277a1",
    "0x5a8738dae6c3482458c888e1fc5fcf30efb0e347",
    "0x5ba18858cc31b6dabae0fca189aedb660b31138d",
    "0x64edc71b5a87134f3391906ce7e6624e32883c10",
    "0x65257ebf5b7c2726fa170b1be6ff7795eba35ff7",
    "0x66df79e293e0c8bf8f9c254ad9f7bd153c89a4a7",
    "0x6a4b2fa082c995f4430dc60341829d70448cccb6",
    "0x6e605753fa64e155fa1407db6595175369a9d56d",
    "0x7094b6b5776d07e365920f0fa20efb8f959f9922",
    "0x74c80dc7e70f5226900d06992624012127ae3689",
    "0x751c156c3a6916abff6a2e127ee8945c99777034",
    "0x764147b036c914ca460dd42494cfa863d3a2c560",
    "0x7974872fbfba8b886e1c772ebc7deff0127fbb42",
    "0x7fe85c5bbe79d41f82f795c6bc0b875e2ccc5cb2",
    "0x867d0ec1510f89ba0c090fbebc393d3895d47b18",
    "0x8a7cbc544726b3f2fb43d08abbff6cae4fa23413",
    "0x8be60628e50769d2d02b8f9d47702a3a52129047",
    "0x9216bb55bcbfce0005001326c0edb772028f29ef",
    "0x92560bf8176cd04d7df16ebb9a2dbce27f1f7eec",
    "0x929013fe1405a7849fe899ff95ec2e9f15b1c182",
    "0x95b786be4b0c042461ea3397ef93400a2c9ea2d5",
    "0x9c99483b81e6865e23106d8a21c9e259fefb952d",
    "0x9fe2e9d7f413e070bc952d2fd13ccb4758dba583",
    "0xa12a3ac253f8a16155bde85802f4ecb5647f3c5f",
    "0xa3636b3a9e2584ddbc5fa18b8cdf7132b2b36c1d",
    "0xa71672a7771fa91441783c2fba5f9bf00d611035",
    "0xa82761d3ac11e98ba099f4c531842cc9cc9a7c6c",
    "0xa8c4d74c085bd6b66e4b6f48b7c3caa0449f3514",
    "0xaedf74546caa4e1e13faa01b153c1e8099d4623b",
    "0xb1b243a3b0b89ec638a9601dc7eb59f7b2c3f3b6",
    "0xb29c02ad7b96337f5e4f2b82452e451d830cfdd0",
    "0xbb1261aa172937f4606565843f395023206d08ee",
    "0xbca7355cc915fe3acf190880580df2220d498947",
    "0xc7706ab700b4a45faededd9e52ae7f03182f6bfa",
    "0xce7af9c682d4ea09fa912fad92a38e1b524417b3",
    "0xd1e9de216900ea3a8abce5ac62e1eb122a35f9c7",
    "0xd82babcc30aa8398bcaf0a18841b5728d1a4ec03",
    "0xd96e4656f8906b215b2ca71a785ace94e1fa278b",
    "0xdb6894f90e43f905d779f96f177b5cc265e3ad96",
    "0xdc5403a94ef5069bffd5f11306baace7974c3c76",
    "0xdce1133749494e4c16f44193174c2efddf792f05",
    "0xe2341fd784f6555071b9312ff57896b846c770d8",
    "0xe87045bc28fd4a4fd7c35f99fb9ba0249fd1351e",
    "0xee1007141db4a93dbcc9dc7b3159a46819032430",
    "0xf3126c32b07b78a8fef1646b4b33d9228e7ddbf5",
    "0xfbb0b893c32dac49a1bf000fa3418ba9f6355fed",
    "0xfe2f612ae972f2c6dcb34c8c40e10d3bb51cc89e",
    "0x9f5547228c842171356a3ce1d97a98686f58a43e",
    "0x221b1abfccae3e06bd617c5b52bd408b4beb5c20",
    "0x6df1fd18aaa9f1dd745e6e3afc3ff8522a556889",
    "0x41c9b806502f4976ed0682e260e9b7811c61f8c9",
    "0x4f7b873c9ee99058ba7ebc2b0ac16b8fbcc99be7",
    "0x91f56c234ee2a61ca80a7bc47226b0e4b29bfd20",
    "0xeb9b9acdf515d141e818ab8234329e92775c8603",
    "0x794e1d07dd02cc8c0ba0cc821aff585a16ccd5a1",
    "0x2097fc0bb41377ee390608a40ae53827a5576fd8",
    "0xeefca716307f3472d3d3eaeb1bdece025188e593",
    "0x39856f5d51ebbfe19305e288b04a8902893e1ed4",
    "0x580ed5585061923e5f4e3edc8038362485b452cd",
    "0x70f09ce1b3534d93254ea7276b287e358ed22cf9",
    "0x7f51588d702b751266cd5a76c5bc4065eea82f3d",
    "0xa133c3626875bf910109a604132f37397d8a4ab4",
    "0xb5237644015a68f8927cc6a81a2cfc49aeb9f3e5",
    "0xbcde22698bffa7731b527bf2c0db047d7f392c4c",
    "0xf28eb6df753b2b937f092eb3abdefb940916d144",
    "0xf3876b9e471b8eaa4e0b482e80eb9bd819652284",
    "0x01855637d1e6bf6f7bd8a8ab046f01fa31710da4",
    "0x08c25d9b2ad8a607483d0ef9bb13059cccad624b",
    "0x15c64c9000ce544525a93ccdb87f161c0368f80c",
    "0x16247aa39b2a30bc61b47214618dfc5d007ef492",
    "0x295c3cab30588418129bd0650cbdabfbea619f08",
    "0x56c3d2efcbe13103dcf6b661df4dfa7b3fc99c48",
    "0x59227ed8eca6769406faabec7aa8cbf24505ca53",
    "0x5b2855975d7dd366e161667dd4c7c047427e7139",
    "0x5f3ca358e464650327ad24def75f22494a349a28",
    "0x62b0184f1c852601bf57df64eba15d54f1c2db2b",
    "0x76f1441f942d50cf19fb43abb78e3977cdc06ee3",
    "0x7aea6baf9daa95ba574cb423fd2eddb823cc34f3",
    "0x8e89b361bec41e78526d23dabce330ec4de2227c",
    "0x964afbc4d4a80346861bb87dbc31a8610ae87fc4",
    "0x9f3a04bb7f13b521ece109035f530dd6090f6b52",
    "0xacdecf79986db9d509e4f3ff6d55eba2f4acbcb0",
    "0xbcd2f316b70bb2614cd90e2f7bee84cf50384e0e",
    "0xbef94a3d9ada7155331f1c8c73fbb1676af718a4",
    "0xcd54aefef4d05f9d26a39043a0732360d1887e70",
    "0xda765c97fee8238c05718396f8b8ffa4a805cc58",
    "0xde8313b25167113da9fe666699e4c081de7169c9",
    "0xe01bf204d2ecfb05dff779fbf8d872ef294cfd0e",
    "0xe329e8e95b3ed74ca80c0660644ef095f796b63a",
    "0xe46afc89d9075d7a7503a9f41fc21d8135514fc2",
    "0xe49303d91a441615c245c35eeca74b2c995fcaf3",
    "0xee10d885bba0800b20b5370c0da6e1a9655b3f01",
    "0xf089ff1efae825451601cbdddbe06f4bc8d13095",
    "0xf6c00016036c0e60f993897edd368fd1c572d8e0",
    "0xfa1cbdf1a7d26a68b0ba67d410cb718b0ab7dfb7",
    "0x014062a141440506369cd68666a604065b44eb07",
    "0x01c09ff266ff761c134a400f5a52a08e0ce3d470",
    "0x044ffe4018944c0e309ca0bd3e386f4d0190d379",
    "0x059b0d30a7e1e38731d1e9595962af185da96e80",
    "0x063da9693199e05366a518ecd8c730eeddba94cb",
    "0x07b2ebf952874cb24903bdcfec6137e325ca93fe",
    "0x0a1ab4e78e8c6017888eb3fc77bf70f7249abba0",
    "0x0c122b296147b20c0d076ea9443b76f46649aed3",
    "0x0e5ac5e54b8310d4ce2407b200d4bcc7169c7c43",
    "0x1115c503e10faff1e5ed2e46cdf65f34d9bfbfa3",
    "0x11395257f6e85b347482eacbaa0abe2723cbba50",
    "0x117ea23e05283bc300ad4406f1944ca670ad46bf",
    "0x15207678aef8f7595ac2c1d3a7cf7bd44c52b116",
    "0x188fda95ad07c18c3a85e91797159e3df7b6bc29",
    "0x1aa19259a5a97cb9dc49ca24b0c34472e690ff06",
    "0x1f60b01a3498ca9ee14e13e9dbfb546d5e124def",
    "0x2b236726a5a3a78776a0ea209309e2d049c313fa",
    "0x30db2c8118db27ddb57f5f169cfec5c391433749",
    "0x320dfbf15fdd732580c6815cbaeb4a136926814f",
    "0x34defb14a69bbc6b14799fcca81049e0492905f0",
    "0x381d263166f41c0c602790f1fecd8a3871bb7dcd",
    "0x3ec8b5db21feeeaf32df5aa89922ddc7f36d78e7",
    "0x40ec4d62d9de40edc5eb8257a5402a2712adb228",
    "0x40f220276c3710e96b738b3a170bab673e512425",
    "0x437370e7157d75153b90bfd2c58f8e00f903ab5a",
    "0x46fada17b8f2b8c0ad4dd5226205ab2eb0e72412",
    "0x470cadae8a1fa11afb33548f454843816f94c6a7",
    "0x4c956a0adfe992ed422d0be40ea488f18bf66a67",
    "0x4fa35c33425a6bf3dc03a89ea21fb340bb3fc995",
    "0x50f7ece9183d1894e7aa9b6b770b6a4bb2b7455c",
    "0x5868c525c939837bfd8930044c9269a5c2816fd6",
    "0x5913c54d5090dff557776ed4abe168c9f888555d",
    "0x5943ca679fac6f9284718adb4065ef284ef5f5ff",
    "0x5a9d7ad35c02b9016bccfa0273f97ca4257bb4ea",
    "0x5aafc5f9efed4aedd9dc56b9e0a8d3737b373526",
    "0x5ac2379b7664d3729bcf82db87c7a702ef93f89b",
    "0x5e65e345a145d2ecf31b0222af5f13db250cb254",
    "0x6169110a063e26890b0f74689faa820721f0364c",
    "0x617ea9daf4f9de81c7b48ad582761396da8aa23c",
    "0x61cfd8ff6b6b5706a895d8de68f77ac6df07a204",
    "0x6257c2e2b1683310916a24ab51907d5d440f2353",
    "0x66975a9dbca6d9db8f0891ce2da2b7ef783a7ba1",
    "0x6c59439214eb37e9e57f692345391088c67ae8ec",
    "0x6f4e9c61437ff1f3420c9b30edbf7240bab865fc",
    "0x73fb71642d9189cf8eb197ddeaa46ca962abf8fc",
    "0x76287cb038acd326730cd00c71b39dd4b5a1224e",
    "0x78d891b9b43f735d7f3eeaa1f3d640d733bf5ffc",
    "0x7c44633446085f35fbeadd503e0b8d619c74d907",
    "0x7f2d16f5b94cb29fb9ead07cb46c0e74e6523c26",
    "0x8851c04d39fa1a5734cbba23576f6e95bbc9ff6a",
    "0x8c2bf3a4504888b0de9688aeccf38a905dcec940",
    "0x938ce1bb3bdd4d82155728fb75a37b67befdcc40",
    "0x9601e5f2bebf117eb7e20c0bb864be548a75036c",
    "0x9d44c131e702ac25cead57943ceac7ecb35b7817",
    "0xa00e0735866fcc8c0f9e75e400b6a2ac22a4eb34",
    "0xa30d0ea89371a4fc7b32a29ca665938c0a767e7e",
    "0xa464b47e7320c8df1a6de6d0e16b00e7b253c1b2",
    "0xa640a51d9175f7174219dbabbd3b7bb2f7e7159b",
    "0xae2965b2b3244ad7ffd5f17c48c06dd98f1e8107",
    "0xb00481decfeccc141e56cd513fe5db1ea98cd9ff",
    "0xb079d24ba76df7208fb55a047275d1f8737654b6",
    "0xb0a7dbfa001e7f710c01924f5fc56cacf5ce9a28",
    "0xb1ed51a37c4b4dc180bdc421ae2eecdb4efa0fbc",
    "0xb2c8c6c86cfd947782efbd6d8cacfc1eb5177e32",
    "0xb4493e8ea20564dc4bd408df89af8a66012a3395",
    "0xb8548ccd35cc8305298813099fec0b8878d93454",
    "0xbbebeab01f77de2ea708307778719c3bf469ad77",
    "0xbe3ab863d271c49fd0272f5930b3d4190f2bc11b",
    "0xbe4b76587af273d2f78477b080bb5e22ff0e560b",
    "0xbf297d35d5a74b8757a70ab138d949c78407b232",
    "0xcd688921a2011ba07d0b7a83891c353c258d5a95",
    "0xd46c7b1ccce12efd411ba091b105f907f68edae4",
    "0xda2e6e221deaaf557c50d50c4a888c7735b9d5a9",
    "0xdf042018566de3fdff7afc0e06e61bdc8ac57457",
    "0xe0794b5654112b9ed19ba35a47cb8f88412d5e07",
    "0xe0eb3a0d414c8785c3ab5c88b20b08dcff4df30f",
    "0xe36060bec7e9c611f4537a82244be0ac0dfd7f43",
    "0xe78fd5fede0ac4f11cfa9e9a1ab9fc166cdfe011",
    "0xebb8e1b09584fd57410169cd60af6cd2d1beaf3b",
    "0xebfa1d8a0a045cb02d3e4c1c41061024d1d6f81a",
    "0xefd6e29ed1b602b4d0410bf008bd667562df1013",
    "0xf09e6ea297c45374711867b563ae1130d334add0",
    "0xf25c7f687e434e35a89eb47bfd4c0b00a095e0b6",
    "0xf3b61befb2a1fdbb1313366f7c960e9e0adc71fe",
    "0xfe627d675cc3dbaba38cabb6fe9fb2a34b8206a9",
  ];

  function check_whitelist(_to) {
    return true;
    for (let i = 0; i < whitelist_addresses.length; i++) {
      if (whitelist_addresses[i].toLowerCase() == _to.toString().toLowerCase()) {
        return true;
      }
      if (i == whitelist_addresses.length - 1) {
        return false;
      }
    }
  }

  class SignHelper {
    constructor(contractAddress, chainId, signer) {
      this.contractAddress = nftAddress;
      this.chainId = chainId;
      this.signer = signer;
    }

    async createSignature(id) {
      const obj = { id };
      const domain = await this._signingDomain();
      const types = {
        EIP712Domain: [{ name: "id", type: "uint" }],
      };
      const signature = await this.signer._signTypedData(domain, types, obj);
      return { ...obj, signature };
    }
    static async getSign(contractAddress, chainId, id) {
      let provider = new providers.Web3Provider(window.ethereum);

      await provider.send("eth_requestAccounts", []);
      let signer = provider.getSigner();
      await signer.getAddress();
      //let signer = new ethers.Wallet("0x8ddadf5b3738c3cf6201bcbc664dd4d96c3cddaddf924c3dfa73772467a4179f");
      console.log("Address: " + signer.address);
      let lm = new SignHelper(contractAddress, chainId, signer);
      let voucher = await lm.createSignature(id);
      //console.log(voucher.signature);
      return voucher.signature;
    }

    async _signingDomain() {
      if (this._domain != null) {
        console.log("this.domain not null: " + this._domain);
        return this._domain;
      }
      const chainId = chain_id;
      this._domain = {
        name: SIGNING_DOMAIN_NAME,
        version: SIGNING_DOMAIN_VERSION,
        verifyingContract: this.contractAddress,
        chainId,
      };
      console.log("this.domain: " + this._domain);
      return this._domain;
    }
    static async getSignFromP(contractAddress, chainId, id) {
      // let provider = new ethers.providers.Web3Provider(window.ethereum)

      //  await provider.send("eth_requestAccounts", []);
      // var signer = provider.getSigner()
      // await signer.getAddress()
      let signer = new Wallet("0x8ddadf5b3738c3cf6201bcbc664dd4d96c3cddaddf924c3dfa73772467a4179f");
      console.log("Address: " + signer.address);
      let lm = new SignHelper(contractAddress, chainId, signer);
      let voucher = await lm.createSignature(id);
      //console.log(voucher.signature);
      return voucher.signature;
    }
  }

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        // Connect using Web3 provider
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();

        // Create an ethers provider from the Web3 provider
        const provider = web3.eth.currentProvider;
        provider.on("accountsChanged", (accounts) => {
          console.log(accounts);
          window.location.reload();
        });

        // Subscribe to chainId change
        provider.on("chainChanged", (chainId) => {
          console.log(chainId);
          window.location.reload();
        });

        // Subscribe to provider connection
        provider.on("connect", (info) => {
          console.log(info);
          //window.location.reload();
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
          console.log(error);
          setIsWalletConnected(false);
        });
        // Get the signer (account) from the provider
        // const signer = provider.getSigner();

        web3.eth.net.getId().then((result) => {
          console.log("Network id: " + result);
          if (parseInt(result) !== parseInt(chain_id)) {
            //result !== 1
            alert("Wrong Network Selected. Switch to Ape Mainnet and Refresh Page");
            try {
              window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: web3.utils.toHex(chain_id) }],
              });
            } catch (e) {
              alert("Error in Switching Chain, Make Sure you have added Ethereum chain in your web3 wallet.");
              // toast.error("Error in Switching Chain, Make Sure you have added Polygon chain in your web3 wallet.");
              window.location.reload();
            }
          }
        });

        let accounts = await web3.eth.getAccounts();
        console.log(accounts[0]);
        setconnectedAddress(accounts[0]);
        setIsWalletConnected(true);
        await fetch_data();
        //handleClose();
      } catch (error) {
        console.log("Error: " + error);
        alert("Error connecting to wallet:", error);
      }
    } else {
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }
  };
  async function buySale() {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      let newprice = salePriceInWei;
      let contract_abi = abi;
      let contract_address = nftAddress;
      let mintValue = mintAmount;

      const contract = new web3.eth.Contract(contract_abi, contract_address);

      const addresses = await web3.eth.getAccounts();
      const address = addresses[0];
      console.log("addresses[0]: " + addresses[0]);

      let price = parseInt(newprice) * parseInt(mintValue);
      //price = Math.round(price * 100) / 100;
      console.log("Price: " + price + "  .........   " + mintValue);
      //   price =0.006;
      try {
        const estemated_Gas = await contract.methods.mint(mintValue.toString()).estimateGas({
          from: address,
          value: price.toString(),
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
        console.log(estemated_Gas);
        await contract.methods.mint(mintValue.toString()).send({
          from: address,
          value: price.toString(),
          gas: estemated_Gas,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
        alert("Mint Successful.");
      } catch (e) {
        show_error_alert(e);
      }

      // await contract.methods.tokenByIndex(i).call();
    } else {
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }
  }
  async function mint_WL() {
    let random_int = Math.floor(Math.random() * 1000000000000);
    if (!isWalletConnected) {
      alert("Wallet not connected");
      return;
    }
    if (Web3.givenProvider) {
      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();
      const contract = new web3.eth.Contract(abi, nftAddress);

      const addresses = await web3.eth.getAccounts();
      const address = addresses[0];
      console.log("addresses[0]: " + addresses[0]);

      // if (!check_whitelist(address)) {
      //   alert("You are not whitelisted.");
      //   return;
      // }

      let price = presalePriceInWei * parseInt(mintAmount);

      //   price =0.006;
      try {
        // await sign_tx();
        let x = SignHelper.getSignFromP(nftAddress, chain_id, random_int);
        let sig = await x.then((result1) => {
          return result1;
        });

        //console.log("Result:  "+sig);
        const estemated_Gas = await contract.methods.mintPresale(mintAmount.toString(), random_int.toString(), sig.toString()).estimateGas({
          from: address,
          value: price.toString(),
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
        console.log(estemated_Gas);
        const result = await contract.methods.mintPresale(mintAmount.toString(), random_int.toString(), sig.toString()).send({
          from: address,
          value: price.toString(),
          gas: estemated_Gas,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
      } catch (e) {
        show_error_alert(e);
      }
    } else {
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }
  }
  async function fetch_data() {
    let contract_abi = abi;
    let contract_address = nftAddress;

    //console.log(contract_abi);

    const web3 = new Web3("https://apechain.calderachain.xyz/http");
    const contract = new web3.eth.Contract(contract_abi, contract_address);
    //await Web3.givenProvider.enable()

    contract.methods.totalSupply().call((err, result) => {
      console.log("error: " + err);
      if (result != null) {
        console.log("Total Supply: " + result);
        settotalSupply(result.toString());
      }
    });

    // contract.methods.presalePrice().call((err, result) => {
    //   console.log("error: " + err);
    //   if (result != null) {
    //     console.log("Presale Price: " + result);
    //     setpresalePriceInWei(result.toString());
    //   }
    // });
    // contract.methods.salePaused().call((err, result) => {
    //   console.log("error: " + err);
    //   if (result != null) {
    //     console.log("Sale Paused: " + result);
    //     setisSalePaused(result);
    //   }
    // });
    // contract.methods.presalePaused().call((err, result) => {
    //   console.log("error: " + err);
    //   if (result != null) {
    //     console.log("Presale Paused: " + result);
    //     setisPresalePaused(result);
    //   }
    // });
    // contract.methods.salePrice().call((err, result) => {
    //   console.log("error: " + err);
    //   if (result != null) {
    //     console.log("Sale Price: " + result);
    //     setsalePriceInWei(result.toString());
    //   }
    // });
  }
  async function show_error_alert(error) {
    let temp_error = error.message.toString();
    console.log(temp_error);
    let error_list = [
      "You cannot mint more than 2 nfts.",
      "You cannot mint more than 100 nfts.",
      "The contract is paused!",
      "Sale is paused!",
      "Minimum 1 NFT has to be minted per transaction",
      "Sold out",
      "Please send correct amount.",
      "It's not time yet",
      "Sent Amount Wrong",
      "Max Supply Reached",
      "You have already Claimed Free Nft.",
      "Presale have not started yet.",
      "Presale Ended.",
      "You are not Whitelisted.",
      "Sent Amount Not Enough",
      "Max 20 Allowed.",
      "insufficient funds",
      "Exceeding Per Tx Limit",
      "mint at least one token",
      "incorrect ether amount",
      "Presale Ended.",
      "Sale is Paused.",
      "You are not whitelisted",
      "Invalid Voucher. Try Again.",
      "Max Supply Reached.",
      "Public sale is not started",
      "Needs to send more eth",
      "Public Sale Not Started Yet!",
      "Exceed max adoption amount",
      "Private Sale Not Started Yet!",
      "Exceed max minting amount",
    ];

    for (let i = 0; i < error_list.length; i++) {
      if (temp_error.includes(error_list[i])) {
        // set ("Transcation Failed")
        alert(error_list[i]);
      }
    }
  }
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 2) {
      newMintAmount = 2;
    }
    setMintAmount(newMintAmount);
  };
  return (
    <div
      className="font-sans min-h-screen flex flex-col items-center justify-center"
      style={{
        backgroundImage: "url('/background.jpeg')", // Update with the path to your background image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // backgroundColor: "rgba(255, 255, 255, 0.8)", // Light overlay for readability
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="min-h-screen flex flex-col items-center justify-center lg:flex-row lg:justify-center p-6">
        {/* <img src="/newImage.png" alt="New Phase Image" className="mb-6 lg:mb-0 rounded-lg shadow-lg w-2/3 lg:w-1/3" /> */}

        <div className="text-center bg-white bg-opacity-90 rounded-xl shadow-xl p-8 w-full max-w-md">
          <img src="/newAnim.png" alt="New Animated Image" className="mb-4 mx-auto w-20 h-20 object-cover rounded-full shadow-md" />

          <div className="border border-gray-300 p-6 rounded-lg shadow-sm">
            <p className="text-xl text-gray-800 font-semibold">Mint Count: {totalSupply}/2222</p>

            {!isWalletConnected && (
              <>
                <button
                  className="bg-indigo-600 text-white px-6 py-2 rounded-md mt-4 font-medium hover:bg-indigo-700 transition duration-300"
                  onClick={connectWallet}
                >
                  Connect Wallet
                </button>
                <p className="text-md text-gray-600 mt-4">Free mint</p>
              </>
            )}

            {isWalletConnected && (
              <>
                <div className="flex justify-center items-center space-x-4 mt-6">
                  <button onClick={decrementMintAmount} className="bg-gray-200 text-gray-700 px-3 py-1 rounded-md hover:bg-gray-300">
                    -
                  </button>
                  <input
                    type="number"
                    value={mintAmount}
                    onChange={handleInputChange}
                    className="w-20 text-center border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-400"
                  />
                  <button onClick={incrementMintAmount} className="bg-gray-200 text-gray-700 px-3 py-1 rounded-md hover:bg-gray-300">
                    +
                  </button>
                </div>

                <button
                  className="bg-indigo-600 text-white px-6 py-2 rounded-md mt-6 font-medium hover:bg-indigo-700 transition duration-300"
                  onClick={buySale}
                >
                  Mint with Metamask
                </button>
                <p className="text-md text-gray-600 mt-4">Free MINT</p>
              </>
            )}
            <p className="text-md text-gray-600 mt-4"></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
